import { Component } from "@angular/core";

@Component({
  selector: "ngx-footer",
  styleUrls: ["./footer.component.scss"],
  template: `
    <span class="created-by"> © TAQA Morocco 2024. Tous droits réservés. </span>
    <div class="socials"></div>
  `,
})
export class FooterComponent {}
