import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";

import "rxjs/add/operator/do";
import { UserService } from "../services/user.service";
import { CustomService } from "../services/custom.service";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private userService: UserService,
    private customService: CustomService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let currentUser = this.userService.currentUserValue;
    if (currentUser && this.customService.isLogged()) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.customService.getToken()}`,
        },
      });
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.userService.logout();
          this.router.navigate(['auth/login']);
        }
        return throwError(error);
      })
    );;
  }
}
