import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
  NB_WINDOW,
  NbMediaBreakpointsService,
  NbMenuItem,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from "@nebular/theme";

import { UserData } from "../../../@core/data/users";
import { LayoutService } from "../../../@core/utils";
import { filter, map, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { Route, Router } from "@angular/router";
import { UserService } from "../../../@core/services/user.service";
import { CustomService } from "../../../@core/services/custom.service";
import { TAQA_LOGO } from "../../consts/global.consts";
import { ROUTES_GENERAL } from "../../consts/routes.consts";

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  currentTheme = "default";
  logo = TAQA_LOGO;
  userMenu: NbMenuItem[] = [
    { title: "Profile", data: { tag: "profil" }, link: ROUTES_GENERAL.DASHBOARD + "/" + ROUTES_GENERAL.PROFOLE },
    { title: "Log out", data: { tag: "logout" } },
  ];

  constructor(
    private userService: UserService,
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private nbMenuService: NbMenuService,
    private router: Router,
    private customService: CustomService
  ) {}

  ngOnInit() {
    this.nbMenuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === "user-menu"),
        map(({ item: { title, data } }) => data)
      )
      .subscribe((data) => {
        const { tag } = data;
        switch (tag) {
          case "logout":
            this.userService.logout();
            this.router.navigate(["auth/login"]);
            break;

          default:
            break;
        }
      });

    this.currentTheme = this.themeService.currentTheme;

    this.user = this.customService.getUserName();

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
      );

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => (this.currentTheme = themeName));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
