import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ERROR_MESSAGE } from "../../@shared/consts/error-messages.consts";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};
@Injectable({
  providedIn: "root",
})
export class HttpService {
  private apiUrl: string = environment.apiUrl;

  constructor(private _http: HttpClient) {}

  /**
   *
   * @param route
   * @param data
   * @returns
   */
  post(route: string, data: any): Observable<any> {
    return this._http.post(
      `${this.apiUrl}/${route}`,
      data,
      httpOptions
    ); /*.pipe(
      map((result) => result),
      catchError((error) => {
        return error;
      })
    );*/
  }
  /**
   *
   * @param route
   * @param params
   * @returns
   */
  get(route: string, params?: any): Observable<any> {
    return this._http
      .get(`${this.apiUrl}/${route}`, {
        ...httpOptions,
        params,
      })
      .pipe(
        map((result) => result),
        catchError((error) => {
          return this.errorHandler(error);
        })
      );
  }

  /**
   *
   * @param route
   * @param data
   * @returns
   */

  update(route: string, data: any): Observable<any> {
    return this._http.put(`${this.apiUrl}/${route}`, data, httpOptions).pipe(
      map((result) => result),
      catchError((error) => {
        return this.errorHandler(error);
      })
    );
  }

  delete(route: string, params: any): Observable<any> {
    return this._http
      .delete(`${this.apiUrl}/${route}`, {
        ...httpOptions,
        params,
      })
      .pipe(
        map((result) => result),
        catchError((error) => this.errorHandler(error))
      );
  }

  public errorHandler(error: any): Observable<any> {
    let errorMessage = "";

    if (error.error instanceof ErrorEvent) {
      // Client-side or network error
      errorMessage = `Error : ${error.error.message}`;
    } else {
      // Backend returned an error response
      errorMessage = `Error Code: ${error.status}\nMessge: ${error.message}`;
      // Handle specific backend errors here (e.g., using switchMap)
      switch (error.status) {
        case 0:
          errorMessage = ERROR_MESSAGE[error.status];
          break;
        case 500:
          errorMessage = ERROR_MESSAGE[error.status];
          break;
      }
    }
    // Return an Observable with the formatted error message
    return throwError(errorMessage);
  }
}
