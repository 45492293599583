export enum ROUTES_GENERAL {
  AUTH = "auth",
  LOGIN = "login",
  REQUEST_PASSWORD = "request-password",
  REGISTER = "register",
  RESET_PASSWORD = "reset-password",
  CONFIRM_OTP = "confirm-otp",
  DASHBOARD = "dashboard",
  ACCESS_REQUEST_VIEW = "access-request-list",
  REQUEST_ACCESS = "request-access",
  MANAGE_USERS = "manage-users",
  PROFOLE = "profile",
  HSE_DOCUMENTS = "hse-documents",
}

export enum ROUTES_AUTH {}
