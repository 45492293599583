import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { PageNotFoundComponent } from "./features/not-found/not-found.component";
import { ROUTES_GENERAL } from "./@shared/consts/routes.consts";
import { AuthGuardService } from "./@core/guards/auth.guard";

export const routes: Routes = [
  {
    path: "",
    redirectTo: `${ROUTES_GENERAL.DASHBOARD}/${ROUTES_GENERAL.ACCESS_REQUEST_VIEW}`,
    pathMatch: "full",
  },
  {
    path: ROUTES_GENERAL.DASHBOARD,
    loadChildren: () =>
      import("./features/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: ROUTES_GENERAL.AUTH,
    loadChildren: () =>
      import("./features/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "**",
    component: PageNotFoundComponent,
  },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
