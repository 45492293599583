import {
  Compiler,
  Component,
  ComponentFactoryResolver,
  Injector,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import {
  DynamicFieldModel,
  FieldValidationModel,
} from "../../model/dynamic-field-model";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "ngx-dynamic-field",
  templateUrl: "./dynamic-field.component.html",
  styleUrls: ["./dynamic-field.component.scss"],
})
export class DynamicFieldComponent implements OnInit, DynamicFieldModel {
  @ViewChild("inputContainer", { read: ViewContainerRef })
  inputContainer: ViewContainerRef;

  @Input()
  formGroup: FormGroup;

  @Input()
  label: string;

  @Input()
  name: string;

  @Input()
  options: any[];

  @Input()
  type: string;

  @Input()
  validations: FieldValidationModel[];

  @Input()
  styleClass: string[];

  // map storing method to load dynamic component
  componentMap: any;

  // component instance
  instance: DynamicFieldModel;

  constructor(
    private cfr: ComponentFactoryResolver,
    private injector: Injector,
    private compiler: Compiler
  ) {}
  async ngOnInit(): Promise<any> {
    this.initComponentMap();
    await this.loadInputComponent();
  }

  async loadInputComponent(): Promise<any> {
    if (this.type) {
      const componentFactory = await this.componentMap[this.type]();
      const compRef = this.inputContainer.createComponent(
        componentFactory,
        undefined,
        this.injector
      );
      this.instance = compRef.instance as DynamicFieldModel;
      this.instance.formGroup = this.formGroup;
      this.instance.name = this.name;
      this.instance.label = this.label;
      this.instance.options = this.options;
      this.instance.validations = this.validations;
    } else {
      throw new Error("Field's Type is missing");
    }
  }

  loadSelectInput = async () => {
    const { SelectInputComponent, SelectInputModule } = await import(
      "./select-input/select-input.component"
    );
    const ngModuleFactory = this.compiler.compileModuleSync(SelectInputModule);
    const ngModule = ngModuleFactory.create(this.inputContainer.injector);
    return ngModule.componentFactoryResolver.resolveComponentFactory(
      SelectInputComponent
    );
  };

  loadTextInput = async () => {
    const { TextInputComponent } = await import(
      "./text-input/text-input.component"
    );
    return this.cfr.resolveComponentFactory(TextInputComponent);
  };

  loadTextAreaInput = async () => {
    const { TextAreaInputComponent, TextAreaInputModule } = await import(
      "./textarea-input/textarea-input.component"
    );
    const ngModuleFactory =
      this.compiler.compileModuleAsync(TextAreaInputModule);
    const ngModule = (await ngModuleFactory).create(
      this.inputContainer.injector
    );
    return ngModule.componentFactoryResolver.resolveComponentFactory(
      TextAreaInputComponent
    );
  };

  loadDatepickerInput = async () => {
    const { DatepickerInputComponent, DatepickerInputModule } = await import(
      "./datepicker-input/datepicker-input.component"
    );
    const ngModuleFactory = this.compiler.compileModuleSync(
      DatepickerInputModule
    );
    const ngModule = ngModuleFactory.create(this.inputContainer.injector);
    return ngModule.componentFactoryResolver.resolveComponentFactory(
      DatepickerInputComponent
    );
  };

  loadCheckBoxInput = async () => {
    const { CheckboxInputComponent, CheckboxInputModule } = await import(
      "./checkbox-input/checkbox-input.component"
    );
    const ngModuleFactory =
      this.compiler.compileModuleAsync(CheckboxInputModule);
    const ngModule = (await ngModuleFactory).create(
      this.inputContainer.injector
    );
    return ngModule.componentFactoryResolver.resolveComponentFactory(
      CheckboxInputComponent
    );
  };

  initComponentMap(): any {
    this.componentMap = {
      select: this.loadSelectInput,
      text: this.loadTextInput,
      datepicker: this.loadDatepickerInput,
      textArea: this.loadTextAreaInput,
      checkbox: this.loadCheckBoxInput,
    };
  }
}
