<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div class="row">
    <div *ngFor="let field of formConfig" [class]="field.styleClass">
      <ngx-dynamic-field
        [formGroup]="formGroup"
        [name]="field.name"
        [type]="field.type"
        [label]="field.label"
        [options]="field.options || []"
        [validations]="field.validations || []"
        [styleClass]="field.styleClass"
      ></ngx-dynamic-field>
    </div>
  </div>
  <div class="flex justify-content-end">
    <button type="submit" nbButton status="primary">
      {{ submitActionLabel }}
    </button>
  </div>
</form>
