import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpService } from "./http.service";
import { catchError, map } from "rxjs/operators";
import { BehaviorSubject, Observable } from "rxjs";
import {
  Confirm_OTP,
  Forgot_Password,
  Login_User,
  Register_User,
  Reset_INFO_USER,
  Reset_PASSWORD_USER,
  Reset_Password,
  User,
} from "../interfaces/global.payloads";
import { CustomService } from "./custom.service";
import { USER_STOREG_NAME } from "../../@shared/consts/global.consts";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private user: any;

  constructor(
    private _httpService: HttpService,
    private customService: CustomService,
    private router: Router
  ) {
    this.user = this.customService.isLogged()
      ? this.customService.getUserInfo()
      : null;
    this.currentUserSubject = new BehaviorSubject<User>(this.user);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public setCurrentUserValue(user: User) {
    this.currentUserSubject.next(user);
  }

  public login(data: Login_User): Observable<any> {
    return this._httpService.post("auth", data).pipe(
      map((result) => result),
      catchError((error) => {
        return this._httpService.errorHandler(error);
      })
    );
  }

  public logout() {
    window.localStorage.removeItem(USER_STOREG_NAME);
    this.currentUserSubject.next(null);
    this.router.navigateByUrl("auth/login");
  }

  public resetPassword(data: Reset_Password) {
    return this._httpService.post("reset-password", data).pipe(
      map((result) => result),
      catchError((error) => this._httpService.errorHandler(error))
    );
  }

  public forgotPassword(data: Forgot_Password) {
    return this._httpService.post("forgot-password", data).pipe(
      map((result) => result),
      catchError((error) => this._httpService.errorHandler(error))
    );
  }

  public comfirmEmail(data: Confirm_OTP) {
    return this._httpService.post("confirm-otp", data).pipe(
      map((result) => result),
      catchError((error) => this._httpService.errorHandler(error))
    );
  }

  public resendOtp(data: Forgot_Password) {
    return this._httpService.post("resend-otp", data).pipe(
      map((result) => result),
      catchError((error) => this._httpService.errorHandler(error))
    );
  }

  public register(data: Register_User) {
    return this._httpService.post("users", data).pipe(
      map((result) => result),
      catchError((error) => this._httpService.errorHandler(error))
    );
  }

  public getAccounts(data: any) {
    return this._httpService.get("users", data).pipe(
      map((result) => result),
      catchError((error) => this._httpService.errorHandler(error))
    );
  }

  public updateUserInfo(data: Reset_INFO_USER) {
    return this._httpService.post("users/update-infos", data).pipe(
      map((result) => result),
      catchError((error) => this._httpService.errorHandler(error))
    );
  }

  public updateUserPassword(data: Reset_PASSWORD_USER) {
    return this._httpService.post("users/change-password", data).pipe(
      map((result) => result),
      catchError((error) => this._httpService.errorHandler(error))
    );
  }

  public deleteUser(id: number) {
    return this._httpService.delete(`users/${id}`, null).pipe(
      map((result) => result),
      catchError((error) => this._httpService.errorHandler(error))
    );
  }
}
