import { Injectable } from "@angular/core";
import { FormGroup, AbstractControl } from "@angular/forms";
import { environment } from "../../../environments/environment";
import { USER_STOREG_NAME } from "../../@shared/consts/global.consts";
// import * as moment from "moment";
// import * as validator from "validator";

@Injectable({
  providedIn: "root",
})
export class CustomService {
  private sessionDuration = environment.sessionDuration;

  constructor() {}

  excludeProperty(data, dataproperties) {
    dataproperties.forEach((element) => {
      delete data[element];
    });
    return data;
  }

  getUserInfo() {
    return localStorage.getItem(USER_STOREG_NAME);
  }

  getUserName() {
    const { data } = JSON.parse(this.getUserInfo());
    const { firstName, lastName } = data?.user;
    return `${firstName} ${lastName}`.toUpperCase();
  }

  setUserInfo(data: any): void {
    const timeStamp = Date.now() + this.sessionDuration * 60 * 1000;
    localStorage.setItem(USER_STOREG_NAME, JSON.stringify({ data, timeStamp }));
  }

  getToken() {
    if(this.getUserInfo() == null){
      return null;
    }
    const { data } = JSON.parse(this.getUserInfo());
    const { token } = data;
    return token;
  }

  getEmail() {
    const { data } = JSON.parse(this.getUserInfo());
    const { email } = data?.user;
    return email;
  }

  getId() {
    const { data } = JSON.parse(this.getUserInfo());
    const { id } = data?.user;
    return id;
  }

  getCompany() {
    const { data } = JSON.parse(this.getUserInfo());
    const { id } = data?.vendor;
    return id;
  }

  isLogged() {
    return this.getUserInfo() != null;
  }

  deleteLocalStorege(name: string): void {
    localStorage.removeItem(name);
  }

  getListPropertiesToExclude() {
    return [];
  }

  resetLocalStorage(name) {
    localStorage.removeItem(name);
  }

  dateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } | null => {
      let f = group.controls[from];
      let t = group.controls[to];

      if (f.value > t.value && f.value && t.value) {
        return {
          datesError: true,
          message: `Date de debu doit être inférieur à la date de fin`,
        };
      } else if (!f.value && t.value) {
        return {
          datesError: true,
          message: `Date de Debu est requis`,
        };
      }
      return null;
    };
  }

  checkifSessionExpiry() {
    const dataObj = JSON.parse(localStorage.getItem(USER_STOREG_NAME));
    if (!dataObj) return null;
    const currentTime = Date.now();
    if (dataObj.timeStamp < currentTime) {
      localStorage.removeItem(USER_STOREG_NAME);
      return false;
    }
    return true;
  }
  generateUUID() {
    // Generate UUID v4 using random values
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  isEmptyObject = (obj) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "")
        return false;
    }
    return true;
  };

  /**
   * Validation Form
   
  ValidateString(control: AbstractControl) {
    if (Boolean(control.value)) {
      if (
        !Validators.matches(control.value, /^([a-zA-Z ]*)$/gi) &&
        control.value
      )
        return { invalidString: true };
      return null;
    }
  }

  ValidateNumber(control: AbstractControl) {
    if (Boolean(control.value)) {
      if (!validator.isInt(control.value.toString()) && control.value)
        return { invalidNumber: true };
      return null;
    }
  }

  ValidateDate(control: AbstractControl) {
    if (Boolean(control.value)) {
      if (!moment(control.value, "YYYY-MM-DD").isValid() && control.value)
        return { invalidDate: true };
      return null;
    }
  }*/
}
