import { Timestamp } from "rxjs-compat";

export interface User {
  token: string;
  user: {
    id: any;
    firstName: string;
    lastName: string;
    identityNumber: string;
    identityType: string;
    email: string;
    phone: string;
  };
  vendor: {
    id: number,
    name: string,
  }
  timeStamp?: any;
}

export interface Register_User {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phone: Number;
  identityType: string;
  identityNumber: string;
  company: string;
}

export interface Login_User {
  email: string;
  password: string;
}

export interface Forgot_Password {
  email: string;
}

export interface Reset_Password {
  token?: string;
  email: string;
  password: string;
  confirmation: string;
}

export interface Confirm_OTP {
  email: string;
  otp: string;
  origin?: string;
}

export interface Companions {
  id?: string;
  identityType: string;
  identityNumber: string;
  newVisitor: string;
  firstName: string;
  lastName: string;
  timeSlot: string;
  phoneNumber: string;
  gsm: false;
  electronicEquipment: string;
  vehicleAccess: boolean;
  motifAccessVehicule: string;
  typeVehicule: string;
  marque: string;
  vehicleNumber: string;
  avecRemorque: boolean;
}

export interface CreateAccessRequest {
  startDate: Date;
  endDate: Date;
  timeSlot: string;
  isEmergencyRequest: boolean;
  emergencyReason: string;
  visitorType: string;
  visitorCategory: string;
  maxDuration: string;
  contactPerson: string;
  visitObjective: string;
  requestDate: Date;
  revision: string;
  isMajMin: string;
  tranche: string;
  identityNumber: string;
  newVisitor: string;
  firstName: string;
  lastName: string;
  company: string;
  comment: string;
  gsm: boolean;
  motifGsm: string;
  hasElectronicEquipment: boolean;
  motifElectronicEquipent: string;
  motifAccessVehicule: string;
  typeVehicule: string;
  marque: string;
  vehicleNumber: string;
  avecRemorque: boolean;
  zones: {
    id: number;
    zoneId: number;
    name: string;
  }[];
  approvers: {
    approverId: number;
    name: string;
  }[];
  companions: Companions[];
}

export enum ERROR_CODE {
  ERROR_0 = 0,
  BAD_CREDENTIALS = 107,
  PASSWORD_EXPIRED = 111,
  FIRST_AUTHENTICATION = 112,
  ERROR_500 = 500,
}

export const CreateAccessRequestEmpty: CreateAccessRequest = {
  startDate: new Date(),
  endDate: new Date(),
  timeSlot: "",
  isEmergencyRequest: false,
  emergencyReason: "",
  visitorType: "",
  visitorCategory: "",
  maxDuration: "",
  contactPerson: "",
  visitObjective: "",
  requestDate: new Date(),
  revision: "",
  isMajMin: "",
  tranche: "",
  identityNumber: "",
  newVisitor: "",
  firstName: "",
  lastName: "",
  company: "",
  comment: "",
  gsm: false,
  motifGsm: "",
  hasElectronicEquipment: false,
  motifElectronicEquipent: "",
  motifAccessVehicule: "",
  typeVehicule: "",
  marque: "",
  vehicleNumber: "",
  avecRemorque: false,
  zones: [],
  approvers: [],
  companions: [],
};

export const CompanionsEmpty: Companions = {
  identityType: "",
  identityNumber: "",
  newVisitor: "",
  firstName: "",
  lastName: "",
  timeSlot: "",
  phoneNumber: "",
  gsm: false,
  electronicEquipment: "",
  vehicleAccess: false,
  motifAccessVehicule: "",
  typeVehicule: "",
  marque: "",
  vehicleNumber: "",
  avecRemorque: false,
};

export interface Reset_INFO_USER {
  userId: number,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  identityType: string,
  identityNumber: string 
}
export interface Reset_PASSWORD_USER {
  userId: number,
  password: string,
  confirmation: string,
}
export interface Document {
  id: number,
  name: string,
  created_at: string,
  value?: string,
  extension?: string,
  delete?: boolean,
}

export interface HseObject {
  id: number,
  status: string,
  created_at: string,
  documents: Document[]
}
export const HseObjectEmpty: HseObject = {
  id: null,
  status: '',
  created_at: '',
  documents: []
}

export interface DOCUMENT_REQUEST {
  type: string,
  extension: string,
  document: string
}

export interface DOCUMENT_OBJECT {
  documents: DOCUMENT_REQUEST[]
}