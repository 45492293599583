import { Component, Input, OnInit } from "@angular/core";
import {
  DynamicFieldModel,
  FieldValidationModel,
} from "../../model/dynamic-field-model";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

@Component({
  selector: "ngx-dynamic-form",
  templateUrl: "./dynamic-form.component.html",
  styleUrls: ["./dynamic-form.component.scss"],
})
export class DynamicFormComponent implements OnInit {
  //@Input()
  formGroup: FormGroup;

  @Input() submitAction: (formGroup: FormGroup) => void;
  @Input() submitActionLabel: string = "Afficher";
  @Input()
  formConfig: any[];

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    console.log(typeof this.formGroup, typeof this.formConfig);

    this.buildForm(this.formConfig);
  }

  buildForm(formConfig: DynamicFieldModel[]): void {
    let form = {};
    formConfig.forEach(
      (field) =>
        (form = {
          ...form,
          [field.name]: new FormControl(
            "",
            this.getFieldValidation(field.validations)
          ),
        })
    );

    this.formGroup = this.fb.group(form);
  }

  getFieldValidation(validations: FieldValidationModel[]): any[] {
    return validations
      ? validations.map((val) => this.getValidatonType(val.name, val.params))
      : [];
  }

  getValidatonType(name, param?): Validators {
    switch (name) {
      case "required":
        return Validators.required;
      case "maxlength":
        return Validators.maxLength(param);
    }
  }

  onSubmit() {
    this.submitAction(this.formGroup);
  }
}
