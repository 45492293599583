import { NbMenuService } from "@nebular/theme";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "ngx-not-found",
  styleUrls: ["./not-found.component.scss"],
  templateUrl: "./not-found.component.html",
})
export class PageNotFoundComponent implements OnInit {
  constructor(
    private menuService: NbMenuService,
    private route: ActivatedRoute
  ) {}

  goToHome() {
    this.menuService.navigateHome();
  }

  ngOnInit(): void {
    console.log("Activated route", this.route.snapshot.url);
  }
}
