import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { UserService } from "../services/user.service";
import { CustomService } from "../services/custom.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService implements CanActivate {
  constructor(
    private userService: UserService,
    private customService: CustomService,
    private readonly router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if(this.customService.getToken()==null){
      this.router.navigate(["auth/login"], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    } else if (this.userService.currentUserValue) {
      
      return this.customService.checkifSessionExpiry();
    }
    this.router.navigate(["auth/login"], {
      queryParams: { returnUrl: state.url },
    });
    return false;
  }
}
