import { DatePipe } from '@angular/common';

const datePipe = new DatePipe('en-US');

export const EMPTY_OBJECT: any = {};
export const EMPTY_ARRAY: any = [];
export const TAQA_LOGO = "../../assets/images/taqa_logo.svg";
export const FIELD_TYPE = Object.freeze({
  Text: "text",
  Select: "select",
  TextArea: "textArea",
  Datepicker: "datepicker",
  Checkbox: "checkbox",
});

export const VIEW_REQUEST_TYPE = {
  VIEW_INIT: "VIEW_INIT",
  VIEW_UPDATE: "VIEW_UPDATE",
};

export const VIEW_TYPE = Object.freeze({
  VIEW_NEW_REQUEST_ACCESS: "VIEW_NEW_REQUEST_ACCESS",
  VIEW_LIST_ACCESS_REQUEST: "VIEW_LIST_ACCESS_REQUEST",
  VIEW_MANAGE_COLLABORATER: "VIEW_MANAGE_COLLABORATER",
  VIEW_HSE_DOCUMENTS: "VIEW_HSE_DOCUMENTS",
});

export const VIEW_COMPONENT_CONTENT = Object.freeze({
  [VIEW_TYPE.VIEW_NEW_REQUEST_ACCESS]: {
    header_title: "Détail de la demande",
    help_like_steper: true,
    help_text: [
      {
        text: "Cette page permet d'introduire ou modifier une demande d'accès au site.",
      },
      {
        text: `La page affiche
        en premier lieu la date et l'heure de la demande et la catégorie du
        visiteur`,
      },
      {
        text: `Choisissez la
        personne demandée, dans la zone de liste`,
      },
      {
        text: `Ensuite, saisissez le n° de la CIN du visiteur principal`,
      },
      {
        text: `puis cliquez sur
        l'icône à droite pour vérifier si le visiteur existe déjà dans le
        système, et dans ce cas son nom, son prénom et sa société sont
        affichées dans les zones de texte correspondantes, et la zone de
        texte "Nouvel intervenant" affiche "Non".`,
      },
      {
        text: `Si le visiteur
        n'existe pas dans le système, la zone de texte "Nouvel intervenant"
        affiche "Non", et dans ce cas introduisez les informations du
        nouveau visiteur.`,
      },
      {
        text: `Ensuite,
        saisissez l'objet de l'intervention, la date de début de la demande,
        la date de fin, les heures de début et de fin et les commentaires.`,
      },
      {
        text: `Toutes les
        informations sont obligatoires à l'exception de l'heure de fin et
        des commentaires.`,
      },
      {
        text: `Après, cliquez
        sur l'onglet "Zones d'accès". Pour ajouter une zone, choisissez-là
        dans la zone de liste, puis cliquez
        sur le bouton "Ajouter".`,
      },
      {
        text: `Refaites
        l'opération pour toutes les zones accessibles par le visiteur
        principal.`,
      },
      {
        text: `Cliquez ensuite
        sur l'onglet "Approbateurs" pour afficher les approbateurs, ceux-ci
        sont affichés automatiquement par le système.`,
      },
      {
        text: `Cliquez ensuite sur l'onglet "Accompagnants".`,
      },
      {
        text: `La procédure
        d'ajout d'un accmpagnant est similaire à celle de saisie des
        informations du visiteur principal.`,
      },
      {
        text: `Les accompagnants sont de la même société que le visiteur principal.`,
      },
      {
        text: `Pour enregistrer la demande, cliquez sur le bouton "Valider."`,
      },
    ],
  },
  [VIEW_TYPE.VIEW_LIST_ACCESS_REQUEST]: {
    header_title: "Liste des demandes d'access",
    help_like_steper: false,
    help_text: [
      {
        text: `Cette page affiche la liste des demandes en attente d'approbation.`,
      },
      {
        text: `Les demandes sont affichées selon la catégorie de l'intervenant.`,
      },
      {
        text: `Vous pouvez faire la recherche selon le critère que vous voulez (Date de début, date de fin, Accueillant, Demandeur, Société, etc...). Pour celà introduisez le critère de recherche dans les zones de saisie en haut, puis cliquez sur le bouton "Valider".`,
      },
      {
        text: `Pour afficher le détail d'une demande, cliquez sur la ligne correspondante dans le tableau.`,
      },
    ],
  },
  [VIEW_TYPE.VIEW_MANAGE_COLLABORATER]: {
    header_title: "Liste des collaborateurs",
  },
  [VIEW_TYPE.VIEW_HSE_DOCUMENTS]: {
    header_title: "Liste des Documents",
  },
});

export const VIEW_COLUMN_SETTING = Object.freeze({
  [VIEW_TYPE.VIEW_LIST_ACCESS_REQUEST]: {
    startDate: {
      title: "Date début",
      type: "date",
    },
    endDate: {
      title: "Date fin",
      type: "date",
    },
    contactPerson: {
      title: "Accueillant",
      type: "string",
    },
    status: {
      title: "Etat demande",
      type: "string",
    },
  },
  [VIEW_TYPE.VIEW_MANAGE_COLLABORATER]: {
    firstName: {
      title: "Nom",
      type: "string",
    },
    lastName: {
      title: "Prénom",
      type: "string",
    },
    phone: {
      title: "Téléphone",
      type: "string",
    },
  },
  [VIEW_TYPE.VIEW_HSE_DOCUMENTS]: {
    id: {
      title: "Id",
      type: "string",
    },
    created_at: {
      title: "Date",
      type: "date",
      valuePrepareFunction: (date) => {
        return datePipe.transform(date, 'dd/MM/yyyy');
      },
    },
    status: {
      title: "Status",
      type: "html",
      valuePrepareFunction: (cell: any, row: any) => {
        if(cell == 'PENDING'){
          return '<span class="bg-warning p-2 text-white rounded">En cours</span>';
        } else if(cell == 'APPROVED'){
          return '<span class="bg-success p-2 text-white rounded">Valide</span>';
        }
        return '<span class="bg-danger p-2 text-white rounded">Rejeté</span>';
      },
    },
  },
});

export const listOfRequestType = ({ type }) =>
  [
    {
      label: "Visiteurs",
      value: "VISITORS",
      show: VIEW_REQUEST_TYPE.VIEW_INIT === type,
    },
    {
      label: "Autorités",
      value: "AUTHORITIES",
      show: VIEW_REQUEST_TYPE.VIEW_INIT === type,
    },
    {
      label: "Sous-Traitants",
      value: "SUBCONTRACTORS",
      show: VIEW_REQUEST_TYPE.VIEW_INIT === type,
    },
    {
      label: "Livraison",
      value: "DELIVERY",
      show: VIEW_REQUEST_TYPE.VIEW_INIT === type,
    },
    {
      label: "Candidats et Stagiaires",
      value: "CANDIDATES_INTERNS",
      show: VIEW_REQUEST_TYPE.VIEW_INIT === type,
    },
  ].filter((item) => item.show ?? true);

export const NOTIFICATION_DURATION = 2000000;
export const USER_STOREG_NAME = "USER_INFO";

export const ALERT_TYPE_MESSAGE = Object.freeze({
  ERROR: "error",
  SUCCESS: "success",
});

export const listPlageTime = Object.freeze([
  { value: "07h00-19h00", label: "Jour (07h00--> 19h00)" },
  { value: "19h00--07h00", label: "Nuit (19h00--07h00)" },
  { value: "weekend", label: "Weekend et jours fériés" },
]);

export const IMMAT_OPTIONS = Object.freeze([
  { value: "A", label: "A - أ" },
  { value: "B", label: "B - ب" },
  { value: "D", label: "D - د" },
  { value: "H", label: "H - ه" },
  { value: "CC", label: "CC - ه ق" },
  { value: "CD", label: "CD - ه د" },
  { value: "CI", label: "CI - ه ي" },
  { value: "OI", label: "OI - و ي" },
  { value: "PAT", label: "PAT - ب أ ت" },
  { value: "CMD", label: "CMD - ه م د" },
  { value: "P", label: "P - ش" },
  { value: "J", label: "J - ج" },
  { value: "G", label: "G - د م" },
  { value: "M", label: "M - م م" },
  { value: "GR", label: "GR - ح م" },
  { value: "FA", label: "FA - ق س" },
  { value: "WM", label: "WM - و م" },
  { value: "CR", label: "CR - ه أ" },
  { value: "E", label: "E - و" },
  { value: "T", label: "T - ط" },
]);

export const STATUS = Object.freeze([
  {
    value: "PENDING",
    label: "En attente",
  },
  {
    value: "VALIDATED",
    label: "Validé",
  },
  {
    value: "ARCHIVED",
    label: "Archivé",
  },
  {
    value: "REJECTED",
    label: "Rejeté",
  },
]);

export const AUTH_ATTEMPTS = 5;

export const IDENTITY_TYPE = Object.freeze([
  { value: "CIN", label: "CIN" },
  { label: "Passport", value: "PASS" },
  { label: "Cart sejour", value: "SEJ" },
]);
